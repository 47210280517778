<template>
  <div class="settings" style=" ">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">
      <workstation-nav></workstation-nav>
      <div class="content" :style="{ 'min-height': windowHeight - 110 + 'px' }">
        <p class="page-header" >Dashboard </p>

        <div class="content-white" style="padding-top: 20px;">

          <p class="page-header" style=" text-align:center; font-size: 20px; margin: 0; margin-bottom: 20px; " >
            <span style="border-bottom:3px solid #2d3640; width: 80px; height: 60px;padding: 7px 0;">DASHBOARD</span>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import WorkstationNav from "../navComponent";

  export default {
    name: "src-pages-body-workstation-dashboard-v1",
    components: {WorkstationNav},
    data () {
      return {
      }
    }
  }
</script>

<style scoped>
  .chatrow{
    height: 80px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }
  .numberCircle span {

    text-align: center;
    line-height: 36px;
    display: block;
    color: #5e6b79;
  }

</style>
